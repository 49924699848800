$icomoon-font-family: 'icomoon-nav' !default;
$icomoon-font-path: '/assets/fonts/icomoonnav' !default;
$icomoon-configure-font-path: '/assets/fonts/icomoonconfigure' !default;

$icon-accesscode: '\e900';
$icon-dockarrow: '\e901';
$icon-camera: '\e902';
$icon-density: '\e903';
$icon-arrow: '\e906';
$icon-back: '\e907';
$icon-captive-portal: '\e914';
$icon-close: '\e915';
$icon-cp-rule: '\e916';
$icon-delete: '\e919';
$icon-download1: '\e91a';
$icon-edit: '\e91c';
$icon-engagement-rule: '\e91d';
$icon-home: '\e91e';
$icon-location: '\e91f';
$icon-mobile-apps: '\e920';
$icon-monitoring: '\e921';
$icon-more: '\e923';
$icon-profile-rule: '\e924';
$icon-reports: '\e925';
$icon-report: '\e909';
$icon-right-now: '\e926';
$icon-settings: '\e927';
$icon-user: '\e928';
$icon-wifi: '\e929';
$icon-edge-device: '\e905';
$icon-integrations: '\e908';
$icon-all-metrics: '\e949';

$icon-custom-reports: '\e90b';
$icon-configure: '\e500';

$icon-baseColor: #9a9a9a;
$icon-baseFontLarge: 30px;
$icon-baseFontLargeMedium: 23px;
$icon-baseFontLargeSmall: 17px;



@font-face {
  font-family: '#{$icomoon-font-family}';
  src: url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?64tqqn');
  src: url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?64tqqn#iefix') format('embedded-opentype'),
  url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?64tqqn') format('truetype'),
  url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?64tqqn') format('woff'),
  url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?64tqqn##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'icomoon';
  src:  url('#{$icomoon-configure-font-path}/icomoon.eot?uaz206');
  src:  url('#{$icomoon-configure-font-path}/icomoon.eot?uaz206#iefix') format('embedded-opentype'),
    url('#{$icomoon-configure-font-path}/icomoon.ttf?uaz206') format('truetype'),
    url('#{$icomoon-configure-font-path}/icomoon.woff?uaz206') format('woff'),
    url('#{$icomoon-configure-font-path}/icomoon.svg?uaz206#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

i {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-dockarrow {
  &:before {
    content: "\e901";
    color: $icon-baseColor;
    font-size: $icon-baseFontLargeMedium;
  }
}
.icon-report {
  &:before {
    content: "\e909";
    color: $icon-baseColor;
    font-size: 20px;
  }
}
.icon-app_header {
  &:before {
    content: "\e90a";
    color: $icon-baseColor;
    font-size: $icon-baseFontLargeSmall;
  }
}

.icon-hamburger{
  &:before {
    content: "\e904";
    color: $icon-baseColor;
    font-size: 20px;
  }
}

.icon-accesscode {
  &:before {
    content: $icon-accesscode;
    color: $icon-baseColor;
    font-size: $icon-baseFontLarge;
  }
}

.icon-admin {
  &:before {
    content: $icon-user;
    color: $icon-baseColor;
    font-size: $icon-baseFontLarge;
  }
}

.icon-arrow {
  &:before {
    content: $icon-arrow;
    color: $icon-baseColor;
    font-size: $icon-baseFontLargeMedium;
  }
}

.icon-back {
  &:before {
    content: $icon-back;
    color: $icon-baseColor;
    font-size: $icon-baseFontLarge;
  }
}

.icon-captive-portal {
  &:before {
    content: $icon-captive-portal;
    color: $icon-baseColor;
    font-size: $icon-baseFontLarge;
  }
}

.icon-close {
  &:before {
    content: $icon-close;
    color: $icon-baseColor;
    font-size: $icon-baseFontLargeMedium;
    font-weight: bold;
  }
}

.icon-cp-rule {
  &:before {
    content: $icon-cp-rule;
    color: $icon-baseColor;
    font-size: $icon-baseFontLargeMedium;
  }
}

.icon-delete {
  &:before {
    content: $icon-delete;
    color: $icon-baseColor;
    font-size: $icon-baseFontLarge;
  }
}


.icon-engagement-rule {
  &:before {
    content: $icon-engagement-rule;
    color: $icon-baseColor;
    font-size: $icon-baseFontLarge;
  }
}

.icon-home {
  &:before {
    content: $icon-home;
    color: $icon-baseColor;
    font-size: $icon-baseFontLarge;
  }
}

.icon-location {
  &:before {
    content: $icon-location;
    color: $icon-baseColor;
    font-size: $icon-baseFontLargeMedium;
  }
}

.icon-mobile-apps {
  &:before {
    content: $icon-mobile-apps;
    color: $icon-baseColor;
    font-size: $icon-baseFontLargeMedium;
  }
}

.icon-monitoring {
  &:before {
    content: $icon-monitoring;
    color: $icon-baseColor;
    font-size: $icon-baseFontLargeMedium;
  }
}

.icon-more {
  &:before {
    content: $icon-more;
    color: $icon-baseColor;
    font-size: $icon-baseFontLarge;
  }
}

.icon-profile-rule {
  &:before {
    content: $icon-profile-rule;
    color: $icon-baseColor;
    font-size: $icon-baseFontLarge;
  }
}

.icon-reports {
  &:before {
    content: $icon-reports;
    color: $icon-baseColor;
    font-size: $icon-baseFontLarge;
  }
}

.icon-right-now {
  &:before {
    content: $icon-right-now;
    color: $icon-baseColor;
    font-size: $icon-baseFontLarge;
  }
}

.icon-settings {
  &:before {
    content: $icon-settings;
    color: $icon-baseColor;
    font-size: $icon-baseFontLargeMedium;
  }
}

.icon-user {
  &:before {
    content: $icon-user;
    color: $icon-baseColor;
    font-size: $icon-baseFontLargeSmall;
  }
}

.icon-wifi {
  &:before {
    content: $icon-wifi;
    color: $icon-baseColor;
    font-size: $icon-baseFontLargeMedium;
  }
}

.icon-camera {
  &:before {
    content: $icon-camera;
    color: $icon-baseColor;
    font-size: $icon-baseFontLargeMedium;
  }
}

.icon-edge-device {
  &:before {
    content: $icon-edge-device;
    color: $icon-baseColor;
    font-size: $icon-baseFontLargeMedium;
  }
}

.icon-integrations {
  &:before {
    content: $icon-integrations;
    color: $icon-baseColor;
    font-size: $icon-baseFontLarge;
  }
}

.icon-density {
  &:before {
    content: $icon-density;
    color: $icon-baseColor;
    font-size: $icon-baseFontLargeMedium;
  }
}
.icon-all-metrics {
  &:before {
    content: $icon-all-metrics;
    color: $icon-baseColor;
    font-size: $icon-baseFontLarge;
  }
}

.icon-configure:before {
  content: $icon-configure;
  color: $icon-baseColor;
  font-size: 21px;
  font-family: "icomoon" !important;
}

.icon-custom-reports {
  &:before {
    content: $icon-custom-reports;
    color: $icon-baseColor;
    font-size: 25px!important;
  }
}

