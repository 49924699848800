/* You can add global styles to this file, and also import other style files */
// Imports
@import "./variables";
@import "./mixins";
@import "./icons";

textarea:focus,
input:focus {
  outline: none;
}

.input-text {
  border: none;
  border-bottom: 1px solid $inputBorderColor;
}

.primary-btn {
  @include btnCommons;
  background: $ciscoBlue;
  min-width: 50px;
  color: #fff;
  padding: 12px;
}

.primary-btn:hover {
  background: #026e97;
  transition: all 0.1s ease-in;
  transition-delay: 0.1s;
}

.secondary-btn {
  @include btnCommons;
  border: 1px solid $ciscoBlue;
  color: $ciscoBlue;
  min-width: 50px;
  padding: 11px;
}

.secondary-btn:hover {
  border: 1px solid #026e97;
  color: #026e97;
  transition: all 0.1s ease-in;
  transition-delay: 0.1s;
}

.main-action-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 140px;
  height: 40px;
  // font-style: italic;
  font-family: ciscoSansBold;
  img {
    margin-right: 5%;
  }
}

.close-icon {
  position: absolute;
  right: 0;
  top: 0;
  padding: 22px;
  font-family: $icoMoonNew;
  color: $closeBtn;
  font-size: 15px;
  cursor: pointer;
  color: #6a6b6c;
  font-weight: bold;
}

.showFullPage {
  width: 80% !important;
  margin: 0 auto !important;
  display: block !important;
}

.userSelectEnabled {
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

.stats-counters .odometer.odometer-auto-theme,
.odometer.odometer-theme-default {
  font-family: "ciscoSansThin" !important;
}

/* For d3-donut-chart */
.d3-donut-arc {
  text {
    text-anchor: middle;
  }
  path {
    stroke: #fff;
    stroke-width: 2px;
  }
}
.d3-donut-tooltip {
  position: absolute;
  text-align: center;
  pointer-events: none;
  white-space: nowrap;
  color: #fff;
  display: table;
  background-color: #263b4c;
  border-radius: 3px;
  padding: 5px 10px;
  z-index: 1000;
  font-family: CiscoSansBold;
  font-size: 13px;
  .tooltip__suffix {
    margin: 0 0 0 5px;
  }
}
.stackedtoolTip {
  background-color: #263b4c;
  border-radius: 3px;
  padding: 5px 10px;
  z-index: 1000;
  font-family: CiscoSansBold;
  font-size: 13px;
}
.stackedtoolTipHeading {
  font-size: 14px;
  padding: 5px;
}
.stackedtoolTip.rightText {
  float: right;
}

/* For Open Roaming */
.openroaming {
  &__global {
    &-statistics {
      &__stat {
        > span {
          > span {
            font-size: 12px;
            margin: 0 0 0 2px;
            text-transform: uppercase;
          }
        }
      }
    }
  }
  &--statistics {
    .mydp .selectiongroup {
      min-width: 135px;
    }
    &__leaderboard {
      > div {
        i {
          font-style: normal;
          span {
            font-size: 12px;
            font-weight: bold;
            text-transform: uppercase;
          }
        }
      }
    }
    &__chart {
      &__total {
        span {
          font-size: 15px;
          margin: 0 0 0 6px;
          text-transform: uppercase;
        }
      }
    }
  }
}

.richmap-action-item {
  line-height: 38px;
  height: 38px;
}

.richmaps-menu-content {
  padding-top: 0px;
  padding-bottom: 0px;
  width: 160px;
  background: #FFFFFF;
  border: 1px solid #DEDEDE;
  box-shadow: 0px 4px 8px rgb(18 18 18 / 10%);
  border-radius: 6px;

  button.mat-menu-item {
    background: #FFFFFF;
    color: #545454;
    border-radius: 6px;
  }

  button.mat-menu-item[disabled] {
    opacity: 0.4;
  }
}

.owl-theme .owl-nav.disabled+.owl-dots{
  margin-top: 0 !important;
  z-index: 99;
  position: relative;
}

.quill_tag {
  border: 1px solid #e5eaf2;
  color: #1d1d1d;
  border-radius: 10px;
  display: inline-block;
  background-color: #f1f4f9;
  outline: none;
  padding: 2px 4px;
  cursor: default;
  font-size: 12px;
  line-height: 14px;
  -webkit-user-modify: read-only;
}

.ql-customDropDown .ql-picker-item:before {
  content: attr(data-label);
}

.ql-customDropDown > span.ql-picker-label::before {
  content: 'Smartlinks';
}

.btnclear {
  display: none !important;
}


// Material Slider CSS
.mat-slider.mat-slider-horizontal .mat-slider-wrapper {
  top: 18px;
}
.mat-slider.mat-slider-horizontal .mat-slider-track-wrapper {
  height: 12px;
  border-radius: 10px
}
.mat-slider.mat-slider-horizontal .mat-slider-track-background,
.mat-slider.mat-slider-horizontal .mat-slider-track-fill {
  height: 100%;
}
.mat-slider.mat-slider-horizontal .mat-slider-track-fill {
  background-color: #0099cb;
}
.mat-accent .mat-slider-thumb {
  height: 30px;
  width: 30px;
  background-color: white;
  border: solid 2px gray;
  bottom: -20px;
  right: -20px;
}
.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  background-color: white;
}

.mat-slider-track-background {
  background-color: gray;
}

.mat-slider-track-fill {
  background-color: #0099cb;
}

// device-placement css added
.shadow-hxl {
  display: none !important;
}
