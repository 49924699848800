/* CMX Insights Branding SCSS */
/* Variables */
$currentHeadingColor: #949398;
$helpButtonColor: #B3B3B3;

$tooltipArrowColor: #fff;
$tooltipArrowShadowColor: rgba(0,0,0,0.5);
$tooltipArrowBorderSize: 14px;
$tooltipArrowBorderSizeShadow: 15px;

/* Header Styles */
.cmxInsights {
    .currentHeading {
        font-size: 16px;
        line-height: 30px;
        color: $currentHeadingColor;
        vertical-align: middle;
        font-family: 'ciscoSansRegular';
    }
    .helpButton .squareButtonTernary {
        width: 72px;
        display: inline-block;
        text-align: center;
        text-decoration: none;
        color: $helpButtonColor;
        font-family: 'ciscoSansBold';
        padding: 10px 14px;
        font-weight: bold;
        border: 1px solid #EAEAEA;
        font-size: 10px;
        max-width: 100%;
        border-radius: 4px;
        text-transform: uppercase;
    }
}    


/* Arrow mark for dynamic tooltip 
	* arrow-top-left
	* arrow-top-middle
	* arrow-top-right
	* arrow-right-top
	* arrow-right-middle
	* arrow-right-bottom
	* arrow-bottom-right
	* arrow-bottom-middle
	* arrow-bottom-left
	* arrow-left-bottom
	* arrow-left-middle
	* arrow-left-top
*/
.dynamic-tooltip {
	&:before,
	&:after {
		position: absolute;
		content: '';
		width: 0;
		height: 0;
		background-color: transparent;
	}
	&.arrow-top-left {
		&:before {
			left: 2%;
			bottom: 100%;
			border-bottom: $tooltipArrowBorderSize solid $tooltipArrowColor;
			border-left: $tooltipArrowBorderSize solid transparent;
			border-right: $tooltipArrowBorderSize solid transparent;
			z-index: 101;
		}
		&:after {
			left: 2%;
			margin-left: -1px;
			bottom: 100%;
			border-bottom: $tooltipArrowBorderSizeShadow solid $tooltipArrowShadowColor;
			border-left: $tooltipArrowBorderSizeShadow solid transparent;
			border-right: $tooltipArrowBorderSizeShadow solid transparent;
			z-index: 100;
		}
	}
	&.arrow-top-middle {
		&:before {
			left: 50%;
			transform: translateX(-50%);
			bottom: 100%;
			border-bottom: $tooltipArrowBorderSize solid $tooltipArrowColor;
			border-left: $tooltipArrowBorderSize solid transparent;
			border-right: $tooltipArrowBorderSize solid transparent;
			z-index: 101;
		}
		&:after {
			left: 50%;
			transform: translateX(-50%);
			bottom: 100%;
			border-bottom: $tooltipArrowBorderSizeShadow solid $tooltipArrowShadowColor;
			border-left: $tooltipArrowBorderSizeShadow solid transparent;
			border-right: $tooltipArrowBorderSizeShadow solid transparent;
			z-index: 100;
		}
	}
	&.arrow-top-right {
		&:before {
			right: 2%;
			bottom: 100%;
			border-bottom: $tooltipArrowBorderSize solid $tooltipArrowColor;
			border-left: $tooltipArrowBorderSize solid transparent;
			border-right: $tooltipArrowBorderSize solid transparent;
			z-index: 101;
		}
		&:after {
			right: 2%;
			margin-right: -1px;
			bottom: 100%;
			border-bottom: $tooltipArrowBorderSizeShadow solid $tooltipArrowShadowColor;
			border-left: $tooltipArrowBorderSizeShadow solid transparent;
			border-right: $tooltipArrowBorderSizeShadow solid transparent;
			z-index: 100;
		}
	}
	&.arrow-right-top {
		&:before {
			top: 2%;
			left: 100%;
			border-left: $tooltipArrowBorderSize solid $tooltipArrowColor;
			border-top: $tooltipArrowBorderSize solid transparent;
			border-bottom: $tooltipArrowBorderSize solid transparent;
			z-index: 101;
		}
		&:after {
			top: 2%;
			margin-top: -1px;
			left: 100%;
			border-left: $tooltipArrowBorderSizeShadow solid $tooltipArrowShadowColor;
			border-top: $tooltipArrowBorderSizeShadow solid transparent;
			border-bottom: $tooltipArrowBorderSizeShadow solid transparent;
			z-index: 100;
		}
	}
	&.arrow-right-middle {
		&:before {
			top: 50%;
			transform: translateY(-50%);
			left: 100%;
			border-left: $tooltipArrowBorderSize solid $tooltipArrowColor;
			border-top: $tooltipArrowBorderSize solid transparent;
			border-bottom: $tooltipArrowBorderSize solid transparent;
			z-index: 101;
		}
		&:after {
			top: 50%;
			transform: translateY(-50%);
			left: 100%;
			border-left: $tooltipArrowBorderSizeShadow solid $tooltipArrowShadowColor;
			border-top: $tooltipArrowBorderSizeShadow solid transparent;
			border-bottom: $tooltipArrowBorderSizeShadow solid transparent;
			z-index: 100;
		}
	}
	&.arrow-right-bottom {
		&:before {
			bottom: 2%;
			left: 100%;
			border-left: $tooltipArrowBorderSize solid $tooltipArrowColor;
			border-top: $tooltipArrowBorderSize solid transparent;
			border-bottom: $tooltipArrowBorderSize solid transparent;
			z-index: 101;
		}
		&:after {
			bottom: 2%;
			margin-bottom: -1px;
			left: 100%;
			border-left: $tooltipArrowBorderSizeShadow solid $tooltipArrowShadowColor;
			border-top: $tooltipArrowBorderSizeShadow solid transparent;
			border-bottom: $tooltipArrowBorderSizeShadow solid transparent;
			z-index: 100;
		}
	}
	&.arrow-bottom-right {
		&:before {
			right: 2%;
			top: 100%;
			border-top: $tooltipArrowBorderSize solid $tooltipArrowColor;
			border-left: $tooltipArrowBorderSize solid transparent;
			border-right: $tooltipArrowBorderSize solid transparent;
			z-index: 101;
		}
		&:after {
			right: 2%;
			margin-right: -1px;
			top: 100%;
			border-top: $tooltipArrowBorderSizeShadow solid $tooltipArrowShadowColor;
			border-left: $tooltipArrowBorderSizeShadow solid transparent;
			border-right: $tooltipArrowBorderSizeShadow solid transparent;
			z-index: 100;
		}
	}
	&.arrow-bottom-middle {
		&:before {
			left: 50%;
			transform: translateX(-50%);
			top: 100%;
			border-top: $tooltipArrowBorderSize solid $tooltipArrowColor;
			border-left: $tooltipArrowBorderSize solid transparent;
			border-right: $tooltipArrowBorderSize solid transparent;
			z-index: 101;
		}
		&:after {
			left: 50%;
			transform: translateX(-50%);
			top: 100%;
			border-top: $tooltipArrowBorderSizeShadow solid $tooltipArrowShadowColor;
			border-left: $tooltipArrowBorderSizeShadow solid transparent;
			border-right: $tooltipArrowBorderSizeShadow solid transparent;
			z-index: 100;
		}
	}
	&.arrow-bottom-left {
		&:before {
			left: 2%;
			top: 100%;
			border-top: $tooltipArrowBorderSize solid $tooltipArrowColor;
			border-left: $tooltipArrowBorderSize solid transparent;
			border-right: $tooltipArrowBorderSize solid transparent;
			z-index: 101;
		}
		&:after {
			left: 2%;
			margin-left: -1px;
			top: 100%;
			border-top: $tooltipArrowBorderSizeShadow solid $tooltipArrowShadowColor;
			border-left: $tooltipArrowBorderSizeShadow solid transparent;
			border-right: $tooltipArrowBorderSizeShadow solid transparent;
			z-index: 100;
		}
	}
	&.arrow-left-top {
		&:before {
			top: 2%;
			right: 100%;
			border-right: $tooltipArrowBorderSize solid $tooltipArrowColor;
			border-top: $tooltipArrowBorderSize solid transparent;
			border-bottom: $tooltipArrowBorderSize solid transparent;
			z-index: 101;
		}
		&:after {
			top: 2%;
			margin-top: -1px;
			right: 100%;
			border-right: $tooltipArrowBorderSizeShadow solid $tooltipArrowShadowColor;
			border-top: $tooltipArrowBorderSizeShadow solid transparent;
			border-bottom: $tooltipArrowBorderSizeShadow solid transparent;
			z-index: 100;
		}
	}
	&.arrow-left-middle {
		&:before {
			top: 50%;
			transform: translateY(-50%);
			right: 100%;
			border-right: $tooltipArrowBorderSize solid $tooltipArrowColor;
			border-top: $tooltipArrowBorderSize solid transparent;
			border-bottom: $tooltipArrowBorderSize solid transparent;
			z-index: 101;
		}
		&:after {
			top: 50%;
			transform: translateY(-50%);
			right: 100%;
			border-right: $tooltipArrowBorderSizeShadow solid $tooltipArrowShadowColor;
			border-top: $tooltipArrowBorderSizeShadow solid transparent;
			border-bottom: $tooltipArrowBorderSizeShadow solid transparent;
			z-index: 100;
		}
	}
	&.arrow-left-bottom {
		&:before {
			bottom: 2%;
			right: 100%;
			border-right: $tooltipArrowBorderSize solid $tooltipArrowColor;
			border-top: $tooltipArrowBorderSize solid transparent;
			border-bottom: $tooltipArrowBorderSize solid transparent;
			z-index: 101;
		}
		&:after {
			bottom: 2%;
			margin-bottom: -1px;
			right: 100%;
			border-right: $tooltipArrowBorderSizeShadow solid $tooltipArrowShadowColor;
			border-top: $tooltipArrowBorderSizeShadow solid transparent;
			border-bottom: $tooltipArrowBorderSizeShadow solid transparent;
			z-index: 100;
		}
	}
}

.app-center-container .star-icon {
	font-size: 1em !important;
	margin-right: 4px !important;
}

.imgUploadContainer .ngx-file-drop__drop-zone {
    border: none !important;
	border-radius: 0 !important;
	height: auto !important;

	.ngx-file-drop__content {
		display: block !important;
		height: auto !important;
	}
}

.activation-popup .locPopup .tree-node-level-1>tree-node-children>.tree-children {
	max-height: 260px !important; 
}

.owl-theme {
	position: relative;

	.owl-nav {
		position: absolute;
		top: 40%;
		transform: translateY(-50%);
		width: 100%;

		.owl-prev {
			position: absolute;
			left: -40px;
			border-radius: 50% !important;
			width: 70px;
			height: 70px;
			padding: 15px !important;
			font-size: 30px !important;
			color: #000 !important;
			background: #fff !important;
			box-shadow: 0 0 4px 0 rgba(0,0,0,0.12);

			&:hover {
				background: #fafafa !important;
			}
		}

		.owl-next {
			position: absolute;
			right: -40px;
			border-radius: 50% !important;
			width: 70px;
			height: 70px;
			padding: 15px !important;
			font-size: 30px !important;
			color: #000 !important;
			background: #fff !important;
			box-shadow: 0 0 4px 0 rgba(0,0,0,0.12);


			&:hover {
				background: #fafafa !important;
			}
		}
	}
}