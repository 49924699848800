@mixin boxShadow {
    -webkit-box-shadow: 0px 1px 3px 1px rgba(189,189,189,0.24);
    -moz-box-shadow: 0px 1px 3px 1px rgba(189,189,189,0.24);
    box-shadow: 0px 1px 3px 1px rgba(189,189,189,0.24);
}

@mixin circleShadow {
    box-shadow: 0px 0px 5px 1px rgba(191, 191, 191, 0.50);
    -moz-box-shadow: 0px 0px 5px 1px rgba(191, 191, 191, 0.50);
    -webkit-box-shadow: 0px 0px 5px 1px rgba(191, 191, 191, 0.50);
}

@mixin tileShadow {
    -webkit-box-shadow: 0px 0px 3px 0.5px rgba(220, 220, 220, 0.55);
    -moz-box-shadow: 0px 0px 3px 0.5px rgba(220, 220, 220, 0.55);
    box-shadow: 0px 0px 3px 0.5px rgba(220, 220, 220, 0.55);
}

@mixin btnCommons {
    display: block;
    cursor: pointer;
    border-radius: 6px;
    letter-spacing: 0.5px;
    border: none;
    text-align: center;
    font-family: ciscoSans;
    font-size: 13px;
}

@mixin ellipses {
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis;
}