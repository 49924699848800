/* 
#joystick {
    background: #FFFFFF;
    position: absolute;
    bottom: 10px;
    left: 10%;
    width: 333px;
    height: 93px;
    padding: 5px 10px;
    color: white;
    border-radius: 5px;
    margin: auto 10px;
    display: none;
}

.model {
    width: 23.5px;
    height: 35px;
    position: relative;
    left: 7px;
}
.js-arrow {
    width: 26px;
    height: 26px;
    cursor: pointer;
}
.up {
    transform: rotate(-90deg);
    position: relative;
    top: -62px;
    left: 6.5px;
}

.down {
    transform: rotate(90deg);
    position: relative;
    left: 6px;
    top: -23px;
}

.right {
    transform: rotate(0deg);
    position: relative;
    left: 39px;
    top: -82px;
}

.left {
    transform: rotate(-180deg);
    position: relative;
    left: -28px;
    top: -107px;
}
.js-rotate {
    margin: auto;
    cursor: pointer;
}

.js-ellipse-large {
    height: 90px;
    width: 90px;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    left: 25px;
}

.js-ellipse-small {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    left: 26px;
    top: 26px;
}

.controller {
    text-align: center
}

.grid-container {
    display: grid;
    grid-template-columns: auto auto auto;
}

.rotate-item {
    display: inline-grid;
    margin: auto;
}

.js-neg {
    transform: scale(-1, 1);
}

.js-person-text {
    font-family: 'CiscoSans';
    font-style: normal;
    font-weight: 350;
    line-height: 16px;
    letter-spacing: 0.5px;
    color: #000000;
    opacity: 0.6;
} */

#rms-map-control {
    position: absolute;
    top: 25px;
    right: 10px;
    font-size: 14px;
    text-align: center;
    border-radius: 10px;
    width: 52px;
    height: 420px;
}

#rms-map-control .sliderDiv {
    position: absolute;
    top: 266px;
    left: -40px;
    opacity: 0.7;
}

#rms-map-control .slider {
    -webkit-appearance: none;
    transform: rotate(90deg);
    height: 2px;
    background: black;
    cursor: pointer;
}

#rms-map-control .slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: black;
}

#rms-map-control .mc-b {
    display: block;
    height: 415px;
}

#rms-map-control .hrLine {
    color: black;
    opacity: 0.4;
    position: absolute;

}

#rms-map-control .sk-qUI {
    margin-right: 10px;
    margin-left: 10px;
}

#rms-map-control .nav-hrLine {
    width: 30px;
    top: 182px;
    left: 11px;
}

#rms-map-control .q-hrLine {
    width: 244px;
    top: 160px;
    left: 20px;
}

#rms-map-control .zoom {
    width: 27.93px;
    height: 27.93px;
    cursor: pointer;
}

#rms-map-control .question {
    width: 28px;
    position: absolute;
    height: 28px;
    top: 385px;
    left: 13px;
    cursor: pointer;
}

#rms-map-control .mapRotate-c {
    top: 150px;
    position: absolute;
    left: 14px;
}

#rms-map-control .mapRotate-ac {
    position: absolute;
    top: 115px;
    left: 14px;
}

#rms-map-control .map-control-container {
    background: white;
    border-radius: 4px;
}

#rms-map-control .qUI {
    width: 285px;
    position: absolute;
    right: 60px;
    background: white;
    height: 325px;
    border-radius: 5px;
    bottom: 0px;
    display: none;
}

#rms-map-control .topText {
    font-weight: 350;
    font-family: Cisco-Sans;
    color: black;
    margin-right: 10px;
    margin-left: 10px;
}

#rms-map-control .ctrl-header {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
    color: #787878;
}

#rms-map-control .ctrl-span {
    color: black;
    position: absolute;
}

#rms-map-control .ctrl-span-pitch-tc {
    left: -38px;
    top: 22px;
}

#rms-map-control .ctrl-span-bearing-tc {
    top: 20px;
    left: -32px;
}

#rms-map-control .ctrl-span-pitch-mc {
    left: 51px;
    top: 116px;
}

#rms-map-control .ctrl-span-bearing-mc {
    left: 51px;
    top: 61px;
}

#rms-map-control .left-ctrl {
    margin-left: 20px;
    float: left;
}

#rms-map-control .right-ctrl {
    margin-right: 10px;
    float: right;
}

#rms-map-control .arr {
    color: black;
    font-size: 11px;
}

#rms-map-control .hand-bearing {
    left: -2px;
    position: absolute;
    top: 3px;
}

#rms-map-control .hand-pitch {
    left: -6px;
    position: absolute;
    top: 8px;
}

#rms-map-control .hDiv {
    position: absolute;
    display: inline-block;
}

#rms-map-control .hDiv-bearing {
    left: 51px;
    top: 175px;
}

#rms-map-control .hDiv-pitch {
    left: 57px;
    top: 233px;
}

#rms-map-control .bear-span {
    color: black;
    position: absolute;
    top: 195px;
    left: 98px;
}

#rms-map-control .pitch-span {
    color: black;
    position: absolute;
    top: 251px;
    left: 106px;
}

#rms-map-control .mouse-bearing {
    position: absolute;
    top: 45px;
    left: 81px;
}

#rms-map-control .mouse-pitch {
    position: absolute;
    left: 81px;
    top: 104px;
}

#rms-map-control .got-span {
    color: #00A0D1;
    font-family: 'CiscoSans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    top: 287px;
    position: absolute;
    left: 225px;
    letter-spacing: 0.5px;
    cursor: pointer;
}

#rms-map-control .mDiv-bearing {
    left: 149px;
}

#rms-map-control .mDiv-pitch {
    left: 149px;
}

/* new rms css started */
#rms-joystick {
    background: #FFFFFF;
    position: absolute;
    bottom: 10px;
    left: 10%;
    width: 333px;
    height: 93px;
    padding: 5px 10px;
    color: white;
    border-radius: 5px;
    margin: auto 10px;
    display: none;
}

.rms-model {
    width: 23.5px;
    height: 35px;
    position: relative;
    left: 7px;
}

.rms-js-arrow {
    width: 26px;
    height: 26px;
}

.rms-up {
    transform: rotate(-90deg);
    position: relative;
    top: -62px;
    left: 6.5px;
}

.rms-down {
    transform: rotate(90deg);
    position: relative;
    left: 6px;
    top: -23px;
}

.rms-right {
    transform: rotate(0deg);
    position: relative;
    left: 39px;
    top: -82px;
}

.rms-left {
    transform: rotate(-180deg);
    position: relative;
    left: -28px;
    top: -107px;
}

.rms-js-rotate {
    margin: auto;
}

.rms-js-ellipse-large {
    height: 90px;
    width: 90px;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    left: 25px;
}

.rms-js-ellipse-small {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    left: 26px;
    top: 26px;
}

.rms-controller {
    text-align: center
}

.rms-grid-container {
    display: grid;
    grid-template-columns: auto auto auto;
}

.rms-rotate-item {
    display: inline-grid;
    margin: auto;
}

.rms-js-neg {
    transform: scale(-1, 1);
}

.rms-js-person-text {
    font-family: 'CiscoSans';
    font-style: normal;
    font-weight: 350;
    line-height: 16px;
    letter-spacing: 0.5px;
    color: #000000;
    opacity: 0.6;
}

#rms-map-control {
    position: absolute;
    top: 25px;
    right: 10px;
    font-size: 14px;
    text-align: center;
    border-radius: 10px;
    width: 52px;
    height: 420px;
}

.rms-slider-div {
    position: absolute;
    top: 266px;
    left: -40px;
    opacity: 0.7;
}

.rms-slider {
    -webkit-appearance: none;
    transform: rotate(90deg);
    height: 2px;
    background: black;
}

.rms-slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: black;
}

.rms-mc-b {
    display: block;
    height: 415px;
}

.rms-hr-line {
    color: black;
    opacity: 0.4;
    position: absolute;

}

.rms-sk-q-ui {
    margin-right: 10px;
    margin-left: 10px;
}

.rms-nav-hr-line {
    width: 30px;
    top: 182px;
    left: 11px;
}

.rms-q-hr-line {
    width: 244px;
    top: 160px;
    left: 20px;
}

.rms-zoom {
    width: 27.93px;
    height: 27.93px;
}

.rms-question {
    width: 28px;
    position: absolute;
    height: 28px;
    top: 385px;
    left: 13px;
}

.rms-map-rotate-c {
    top: 150px;
    position: absolute;
    left: 14px;
}

.rms-map-rotate-ac {
    position: absolute;
    top: 115px;
    left: 14px;
}

.rms-map-control-container {
    background: white;
    border-radius: 4px;
}

.rms-q-ui {
    width: 285px;
    position: absolute;
    right: 60px;
    background: white;
    height: 325px;
    border-radius: 5px;
    bottom: 0px;
    display: none;
}

.rms-top-text {
    font-weight: 350;
    font-family: Cisco-Sans;
    color: black;
    margin-right: 10px;
    margin-left: 10px;
}

.rms-ctrl-header {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
    color: #787878;
}

.rms-ctrl-span {
    color: black;
    position: absolute;
}

.rms-ctrl-span-pitch-tc {
    left: -38px;
    top: 22px;
}

.rms-ctrl-span-bearing-tc {
    top: 20px;
    left: -32px;
}

.rms-ctrl-span-pitch-mc {
    left: 51px;
    top: 116px;
}

.rms-ctrl-span-bearing-mc {
    left: 51px;
    top: 61px;
}

.rms-left-ctrl {
    margin-left: 20px;
    float: left;
}

.rms-right-ctrl {
    margin-right: 10px;
    float: right;
}

.rms-hand-bearing {
    left: -2px;
    position: absolute;
    top: 3px;
}

.rms-hand-pitch {
    left: -6px;
    position: absolute;
    top: 8px;
}

.rms-h-div {
    position: absolute;
    display: inline-block;
}

.rms-h-div-bearing {
    left: 51px;
    top: 175px;
}

.rms-h-div-pitch {
    left: 57px;
    top: 233px;
}

.rms-bear-span {
    color: black;
    position: absolute;
    top: 195px;
    left: 98px;
}

.rms-pitch-span {
    color: black;
    position: absolute;
    top: 251px;
    left: 106px;
}

.rms-mouse-bearing {
    position: absolute;
    top: 45px;
    left: 81px;
}

.rms-mouse-pitch {
    position: absolute;
    left: 81px;
    top: 104px;
}

.rms-got-span {
    color: #00A0D1;
    font-family: 'CiscoSans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    top: 287px;
    position: absolute;
    left: 225px;
    letter-spacing: 0.5px;
    cursor: pointer;
}

.rms-m-div-bearing {
    left: 149px;
}

.rms-m-div-pitch {
    left: 149px;
}