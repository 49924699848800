/* Proximity MX Theme Styling */
/* Variables */
$currentHeadingColor: #000;
$helpButtonColor: #000;
$buttonHighlightColor: #007199;

$tooltipArrowColor: #fff;
$tooltipArrowShadowColor: rgba(0, 0, 0, 0.5);
$tooltipArrowBorderSize: 14px;
$tooltipArrowBorderSizeShadow: 15px;

.proximityMX {

  /* Header Styles */
  .currentHeading {
    font-size: 18px;
    line-height: 30px;
    color: $currentHeadingColor;
    vertical-align: middle;
    font-family: 'ciscoSansRegular';
  }

  .helpButton {
    .squareButtonTernary {
      width: auto;
      display: inline-block;
      text-align: center;
      text-decoration: none;
      color: $helpButtonColor;
      font-family: 'ciscoSansThin';
      border: 1px solid #b3afaf;
      border-radius: 50px;
      font-size: 16px;
      padding: 7px 24px;

      &:hover {
        opacity: .7;
      }
    }
  }

  /* Button styling */
  .commonButton {
    font-family: 'ciscoSansRegular';

    &:hover {
      border-color: $buttonHighlightColor;
      color: $buttonHighlightColor;
      font-weight: bold;
    }

    &.plusButton {
      position: relative;
      padding-left: 38px;

      &:before {
        position: absolute;
        left: 18px;
        font-size: 10px;
        font-family: 'icomoon-new';
        color: inherit;
        top: 11px;
        content: '\e913';
        background-color: transparent;
      }
    }
  }
}

/* Login Slider SCSS */
//colors
$white: #fff;

//fonts
$font_0: ciscoSansThin;

//urls
$url_0: url(/assets/images/login/banner_bg2.jpg);
$url_1: url(/assets/images/login/banner_bg3.jpg);
$url_2: url(/assets/images/login/banner_bg4.jpg);
$url_3: url(/assets/images/login/arrow_left.png);
$url_4: url(/assets/images/login/arrow_right.png);

.carousel {
  width: 100%;
  position: relative;
  overflow: hidden;
  margin: 0 auto;
  height: 700px;

  .carousel__content {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 700px;

    .item {
      display: block;
      float: left;
      width: 100%;
      position: relative;

      .title {
        position: absolute;
        top: 50%;
        left: 0;
        margin: -33px 0 0;
        padding: 0;
        font-size: 3rem;
        width: 100%;
        text-align: center;
        letter-spacing: .3rem;
        color: $white;
      }

      .title--sub {
        margin-top: 20px;
        font-size: 1.2em;
        opacity: .5;
      }

      img {
        width: 100%;
        max-width: 100%;
        display: block;
      }
    }
  }

  .nav {
    position: absolute;
    top: 40%;
    left: 0;
    color: $white;
    padding: 8px 24px;
    text-decoration: none;
    font-size: .8rem;
    //Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
    transition: padding .25s ease;
    font-size: 45px;
    height: 45px;
    font-family: $font_0;
    cursor: pointer;
    margin-top: -17px;
    z-index: 1;

    &:hover {
      padding: 8px 30px;
    }
  }

  .nav-left {
    //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
    border-radius: 0 3px 3px 0;
    background-image: $url_3;
    background-size: auto 100%;
    background-position: center 0;
    background-repeat: no-repeat;
  }

  .nav-right {
    left: auto;
    right: 0;
    //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
    border-radius: 3px 0 0 3px;
    background-image: $url_4;
    background-size: auto 100%;
    background-position: center 0;
    background-repeat: no-repeat;
  }
}

.itembg1 {
  background-image: $url_0;
  background-size: cover;
  background-position: left top;
  background-repeat: no-repeat;
}

.itembg2 {
  background-image: $url_1;
  background-size: cover;
  background-position: left top;
  background-repeat: no-repeat;
}

.itembg3 {
  background-image: $url_2;
  background-size: cover;
  background-position: left top;
  background-repeat: no-repeat;
}

.slick-slider {
  position: relative;
  display: block;
  //Instead of the line below you could use @include box-sizing($bs)
  box-sizing: border-box;
  //Instead of the line below you could use @include user-select($select)
  user-select: none;
  touch-action: pan-y;

  .slick-track {
    //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
    transform: translate3d(0, 0, 0);
  }

  .slick-list {
    //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
    transform: translate3d(0, 0, 0);
  }
}

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;

  &:focus {
    outline: none;
  }

  &.dragging {
    cursor: pointer;
    cursor: hand;
  }
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;

  &:before {
    display: table;
    content: '';
  }

  &:after {
    display: table;
    content: '';
    clear: both;
  }
}

.slick-loading {
  .slick-track {
    visibility: hidden;
  }

  .slick-slide {
    visibility: hidden;
  }
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;

  img {
    display: block;
  }

  &.slick-loading img {
    display: none;
  }

  &.dragging img {
    pointer-events: none;
  }
}

[dir='rtl'] .slick-slide {
  float: right;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/* End of Login Slider SCSS */

/* Arrow mark for dynamic tooltip
	* arrow-top-left
	* arrow-top-middle
	* arrow-top-right
	* arrow-right-top
	* arrow-right-middle
	* arrow-right-bottom
	* arrow-bottom-right
	* arrow-bottom-middle
	* arrow-bottom-left
	* arrow-left-bottom
	* arrow-left-middle
	* arrow-left-top
*/
.dynamic-tooltip {

  &:before,
  &:after {
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    background-color: transparent;
  }

  &.arrow-top-left {
    &:before {
      left: 2%;
      bottom: 100%;
      border-bottom: $tooltipArrowBorderSize solid $tooltipArrowColor;
      border-left: $tooltipArrowBorderSize solid transparent;
      border-right: $tooltipArrowBorderSize solid transparent;
      z-index: 101;
    }

    &:after {
      left: 2%;
      margin-left: -1px;
      bottom: 100%;
      border-bottom: $tooltipArrowBorderSizeShadow solid $tooltipArrowShadowColor;
      border-left: $tooltipArrowBorderSizeShadow solid transparent;
      border-right: $tooltipArrowBorderSizeShadow solid transparent;
      z-index: 100;
    }
  }

  &.arrow-top-middle {
    &:before {
      left: 50%;
      transform: translateX(-50%);
      bottom: 100%;
      border-bottom: $tooltipArrowBorderSize solid $tooltipArrowColor;
      border-left: $tooltipArrowBorderSize solid transparent;
      border-right: $tooltipArrowBorderSize solid transparent;
      z-index: 101;
    }

    &:after {
      left: 50%;
      transform: translateX(-50%);
      bottom: 100%;
      border-bottom: $tooltipArrowBorderSizeShadow solid $tooltipArrowShadowColor;
      border-left: $tooltipArrowBorderSizeShadow solid transparent;
      border-right: $tooltipArrowBorderSizeShadow solid transparent;
      z-index: 100;
    }
  }

  &.arrow-top-right {
    &:before {
      right: 2%;
      bottom: 100%;
      border-bottom: $tooltipArrowBorderSize solid $tooltipArrowColor;
      border-left: $tooltipArrowBorderSize solid transparent;
      border-right: $tooltipArrowBorderSize solid transparent;
      z-index: 101;
    }

    &:after {
      right: 2%;
      margin-right: -1px;
      bottom: 100%;
      border-bottom: $tooltipArrowBorderSizeShadow solid $tooltipArrowShadowColor;
      border-left: $tooltipArrowBorderSizeShadow solid transparent;
      border-right: $tooltipArrowBorderSizeShadow solid transparent;
      z-index: 100;
    }
  }

  &.arrow-right-top {
    &:before {
      top: 2%;
      left: 100%;
      border-left: $tooltipArrowBorderSize solid $tooltipArrowColor;
      border-top: $tooltipArrowBorderSize solid transparent;
      border-bottom: $tooltipArrowBorderSize solid transparent;
      z-index: 101;
    }

    &:after {
      top: 2%;
      margin-top: -1px;
      left: 100%;
      border-left: $tooltipArrowBorderSizeShadow solid $tooltipArrowShadowColor;
      border-top: $tooltipArrowBorderSizeShadow solid transparent;
      border-bottom: $tooltipArrowBorderSizeShadow solid transparent;
      z-index: 100;
    }
  }

  &.arrow-right-middle {
    &:before {
      top: 50%;
      transform: translateY(-50%);
      left: 100%;
      border-left: $tooltipArrowBorderSize solid $tooltipArrowColor;
      border-top: $tooltipArrowBorderSize solid transparent;
      border-bottom: $tooltipArrowBorderSize solid transparent;
      z-index: 101;
    }

    &:after {
      top: 50%;
      transform: translateY(-50%);
      left: 100%;
      border-left: $tooltipArrowBorderSizeShadow solid $tooltipArrowShadowColor;
      border-top: $tooltipArrowBorderSizeShadow solid transparent;
      border-bottom: $tooltipArrowBorderSizeShadow solid transparent;
      z-index: 100;
    }
  }

  &.arrow-right-bottom {
    &:before {
      bottom: 2%;
      left: 100%;
      border-left: $tooltipArrowBorderSize solid $tooltipArrowColor;
      border-top: $tooltipArrowBorderSize solid transparent;
      border-bottom: $tooltipArrowBorderSize solid transparent;
      z-index: 101;
    }

    &:after {
      bottom: 2%;
      margin-bottom: -1px;
      left: 100%;
      border-left: $tooltipArrowBorderSizeShadow solid $tooltipArrowShadowColor;
      border-top: $tooltipArrowBorderSizeShadow solid transparent;
      border-bottom: $tooltipArrowBorderSizeShadow solid transparent;
      z-index: 100;
    }
  }

  &.arrow-bottom-right {
    &:before {
      right: 2%;
      top: 100%;
      border-top: $tooltipArrowBorderSize solid $tooltipArrowColor;
      border-left: $tooltipArrowBorderSize solid transparent;
      border-right: $tooltipArrowBorderSize solid transparent;
      z-index: 101;
    }

    &:after {
      right: 2%;
      margin-right: -1px;
      top: 100%;
      border-top: $tooltipArrowBorderSizeShadow solid $tooltipArrowShadowColor;
      border-left: $tooltipArrowBorderSizeShadow solid transparent;
      border-right: $tooltipArrowBorderSizeShadow solid transparent;
      z-index: 100;
    }
  }

  &.arrow-bottom-middle {
    &:before {
      left: 50%;
      transform: translateX(-50%);
      top: 100%;
      border-top: $tooltipArrowBorderSize solid $tooltipArrowColor;
      border-left: $tooltipArrowBorderSize solid transparent;
      border-right: $tooltipArrowBorderSize solid transparent;
      z-index: 101;
    }

    &:after {
      left: 50%;
      transform: translateX(-50%);
      top: 100%;
      border-top: $tooltipArrowBorderSizeShadow solid $tooltipArrowShadowColor;
      border-left: $tooltipArrowBorderSizeShadow solid transparent;
      border-right: $tooltipArrowBorderSizeShadow solid transparent;
      z-index: 100;
    }
  }

  &.arrow-bottom-left {
    &:before {
      left: 2%;
      top: 100%;
      border-top: $tooltipArrowBorderSize solid $tooltipArrowColor;
      border-left: $tooltipArrowBorderSize solid transparent;
      border-right: $tooltipArrowBorderSize solid transparent;
      z-index: 101;
    }

    &:after {
      left: 2%;
      margin-left: -1px;
      top: 100%;
      border-top: $tooltipArrowBorderSizeShadow solid $tooltipArrowShadowColor;
      border-left: $tooltipArrowBorderSizeShadow solid transparent;
      border-right: $tooltipArrowBorderSizeShadow solid transparent;
      z-index: 100;
    }
  }

  &.arrow-left-top {
    &:before {
      top: 2%;
      right: 100%;
      border-right: $tooltipArrowBorderSize solid $tooltipArrowColor;
      border-top: $tooltipArrowBorderSize solid transparent;
      border-bottom: $tooltipArrowBorderSize solid transparent;
      z-index: 101;
    }

    &:after {
      top: 2%;
      margin-top: -1px;
      right: 100%;
      border-right: $tooltipArrowBorderSizeShadow solid $tooltipArrowShadowColor;
      border-top: $tooltipArrowBorderSizeShadow solid transparent;
      border-bottom: $tooltipArrowBorderSizeShadow solid transparent;
      z-index: 100;
    }
  }

  &.arrow-left-middle {
    &:before {
      top: 50%;
      transform: translateY(-50%);
      right: 100%;
      border-right: $tooltipArrowBorderSize solid $tooltipArrowColor;
      border-top: $tooltipArrowBorderSize solid transparent;
      border-bottom: $tooltipArrowBorderSize solid transparent;
      z-index: 101;
    }

    &:after {
      top: 50%;
      transform: translateY(-50%);
      right: 100%;
      border-right: $tooltipArrowBorderSizeShadow solid $tooltipArrowShadowColor;
      border-top: $tooltipArrowBorderSizeShadow solid transparent;
      border-bottom: $tooltipArrowBorderSizeShadow solid transparent;
      z-index: 100;
    }
  }

  &.arrow-left-bottom {
    &:before {
      bottom: 2%;
      right: 100%;
      border-right: $tooltipArrowBorderSize solid $tooltipArrowColor;
      border-top: $tooltipArrowBorderSize solid transparent;
      border-bottom: $tooltipArrowBorderSize solid transparent;
      z-index: 101;
    }

    &:after {
      bottom: 2%;
      margin-bottom: -1px;
      right: 100%;
      border-right: $tooltipArrowBorderSizeShadow solid $tooltipArrowShadowColor;
      border-top: $tooltipArrowBorderSizeShadow solid transparent;
      border-bottom: $tooltipArrowBorderSizeShadow solid transparent;
      z-index: 100;
    }
  }
}


.support-mat-menu+* .mat-menu-panel {

  background: #FFFFFF;
  /* Neutral/5 - Disabled icon and non-text UI elements */

  border: 2px solid #D0D4D9;
  /* Card/Subtle */

  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  margin-top: 15px;
  width: 362px;

  button.mat-menu-item {
    background: #FFFFFF;
    height: 38px;
    margin: 10px 0px;
  }

  .nav-divider-v {
    height: 1px;
    background: #E1E4E8;
    border: 0;
  }
}

// .overlay-sub-menu-item .mat-nav-list {
// 	padding-bottom: 8px;
// }

// Magnetic UI menu item
mat-nav-list .mat-list-item-a div.mat-list-item-content {
  // padding: 0 6px !important;
  display: flex !important;
  padding: 0px !important;
}

mat-nav-list .mat-app-sel-a div.mat-list-item-content {
  // padding: 0 6px !important;
  display: flex !important;
  margin: 0 5px;
}

.app-sel-dialog-container .mat-dialog-container {
  padding: 0px !important
}

.mat-dialog-container {
  animation: none;
  -webkit-animation: none;
  padding: 0px !important
}
