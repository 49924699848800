// Base Colors
$bgColor: #fafafa;
$bgColor2: #f1f1f1;;
$bgColor3: #f5f5f5;
$bgWhite: #ffffff;
$ciscoBlue: #049FD9;
$statusBlue: #63BBE3;
$sageGreen: #ABC232;
$inputBorderColor: #a7a9ab;

// Text Colors
$darkGray1: #6A6B6C;
$darkGray2: #58595B;
$darkGray3: #626469;
$darkGray4: #6a6a6a;
$darkGray5: #9b9b9b;
$warmGray: #999999;
$warmGray1: #fbfbfb;
$mediumGray1: #9E9EA2;
$lightGray1: #C6C7CA;
$lightGray2: #E9E9E9;
$paleGray1: #E8EBF1;
$darkBlue: #1d3243;
$closeBtn:  #6a6b6c;

// Status Colors
$errorRed: #CF2030;
$warningOrange: #FF7301;
$successGreen: #42A942;
$infoYellow: #FFCC01;
$infoBlue: #63BBE3;
$infoGreen: #15A792;
$selectionGreen: #7CC242;
$selectionNavGreen: #abc233;

// TileColors
$tileBlue: #049fd9;
$tilePastel : #43bebd;
$tileDarkBlue : #005072;
$coolBlue: #467fad;

// Fonts

$ciscoSans: ciscoSans;
$ciscoRegular: ciscoSansRegular;
$ciscoBold: ciscoSansBold;
$ciscoThin: ciscoSansThin;
$ciscoLight: ciscoSansLight;
$ciscoExtraLight: ciscoSansExtraLight;
$ciscoCondensed: ciscoSansCondensed;

$icoMoon: icomoon;
$icoMoonNew: icomoon-new;

@font-face {
  font-family: 'ciscoSansRegular';
  font-display: auto;
  src: url('/assets/fonts/Cisco_Sans/CiscoSans.eot');
  src: url('/assets/fonts/Cisco_Sans/CiscoSans.eot?#iefix') format('embedded-opentype'), url('/assets/fonts/Cisco_Sans/CiscoSans.woff2') format('woff2'), url('/assets/fonts/Cisco_Sans/CiscoSans.woff') format('woff'), url('/assets/fonts/Cisco_Sans/CiscoSans.ttf') format('truetype'), url('/assets/fonts/Cisco_Sans/CiscoSans.svg') format('svg');
}

@font-face {
  font-family: 'ciscoSansBold';
  font-display: auto;
  src: url('/assets/fonts/Cisco_Sans/CiscoSans_Bold.eot');
  src: url('/assets/fonts/Cisco_Sans/CiscoSans_Bold.eot?#iefix') format('embedded-opentype'), url('/assets/fonts/Cisco_Sans/CiscoSans_Bold.woff2') format('woff2'), url('/assets/fonts/Cisco_Sans/CiscoSans_Bold.woff') format('woff'), url('/assets/fonts/Cisco_Sans/CiscoSans_Bold.ttf') format('truetype'), url('/assets/fonts/Cisco_Sans/CiscoSans_Bold.svg') format('svg');
}

@font-face {
  font-family: 'ciscoSansThin';
  font-display: auto;
  src: url('/assets/fonts/Cisco_Sans/CiscoSans_Thin.eot');
  src: url('/assets/fonts/Cisco_Sans/CiscoSans_Thin.eot?#iefix') format('embedded-opentype'), url('/assets/fonts/Cisco_Sans/CiscoSans_Thin.woff2') format('woff2'), url('/assets/fonts/Cisco_Sans/CiscoSans_Thin.woff') format('woff'), url('/assets/fonts/Cisco_Sans/CiscoSans_Thin.ttf') format('truetype'), url('/assets/fonts/Cisco_Sans/CiscoSans_Thin.svg') format('svg');
}

@font-face {
  font-family: 'ciscoSansExtraLight';
  font-display: auto;
  src: url('/assets/fonts/Cisco_Sans/CiscoSans_ExtraLight.eot');
  src: url('/assets/fonts/Cisco_Sans/CiscoSans_ExtraLight.eot?#iefix') format('embedded-opentype'), url('/assets/fonts/Cisco_Sans/CiscoSans_ExtraLight.woff2') format('woff2'), url('/assets/fonts/Cisco_Sans/CiscoSans_ExtraLight.woff') format('woff'), url('/assets/fonts/Cisco_Sans/CiscoSans_ExtraLight.ttf') format('truetype'), url('/assets/fonts/Cisco_Sans/CiscoSans_ExtraLight.svg') format('svg');
}

@font-face {
  font-family: 'ciscoSansLight';
  font-display: auto;
  src: url('/assets/fonts/Cisco_Sans/CiscoSans_ExtraLight.eot');
  src: url('/assets/fonts/Cisco_Sans/CiscoSans_ExtraLight.eot?#iefix') format('embedded-opentype'), url('/assets/fonts/Cisco_Sans/CiscoSans_ExtraLight.woff2') format('woff2'), url('/assets/fonts/Cisco_Sans/CiscoSans_ExtraLight.woff') format('woff'), url('/assets/fonts/Cisco_Sans/CiscoSans_ExtraLight.ttf') format('truetype'), url('/assets/fonts/Cisco_Sans/CiscoSans_ExtraLight.svg') format('svg');
}

@font-face {
  font-family: 'ciscoSansCondensed';
  font-display: auto;
  src: url('/assets/fonts/Cisco_Sans/CiscoSans_Condensed.eot');
  src: url('/assets/fonts/Cisco_Sans/CiscoSans_Condensed.eot?#iefix') format('embedded-opentype'), url('/assets/fonts/Cisco_Sans/CiscoSans_Condensed.woff2') format('woff2'), url('/assets/fonts/Cisco_Sans/CiscoSans_Condensed.woff') format('woff'), url('/assets/fonts/Cisco_Sans/CiscoSans_Condensed.ttf') format('truetype'), url('/assets/fonts/Cisco_Sans/CiscoSans_Condensed.svg') format('svg');
}

@font-face {
  font-family: 'ciscoSans';
  font-display: auto;
  src: url('/assets/fonts/Cisco_Sans/CiscoSans.eot');
  src: url('/assets/fonts/Cisco_Sans/CiscoSans.eot?#iefix') format('embedded-opentype'), url('/assets/fonts/Cisco_Sans/CiscoSans.woff2') format('woff2'), url('/assets/fonts/Cisco_Sans/CiscoSans.woff') format('woff'), url('/assets/fonts/Cisco_Sans/CiscoSans.ttf') format('truetype'), url('/assets/fonts/Cisco_Sans/CiscoSans.svg') format('svg');
}

@font-face {
  font-family: 'icomoon';
  font-display: auto;
  src: url('/assets/fonts/icomoon.eot?llzbkh');
  src: url('/assets/fonts/icomoon.eot?llzbkh#iefix') format('embedded-opentype'), url('/assets/fonts/icomoon.ttf?llzbkh') format('truetype'), url('/assets/fonts/icomoon.woff?llzbkh') format('woff'), url('/assets/fonts/icomoon.svg?llzbkh#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'icomoon-new';
  font-display: auto;
  src: url('/assets/fonts/icomoonnew/icomoon.eot?-psryvp');
  src: url('/assets/fonts/icomoonnew/icomoon.eot?#iefix-psryvp') format('embedded-opentype'), url('/assets/fonts/icomoonnew/icomoon.ttf?-psryvp') format('truetype'), url('/assets/fonts/icomoonnew/icomoon.woff?-psryvp') format('woff'), url('/assets/fonts/icomoonnew/icomoon.svg?-psryvp#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

/* Cisco Sans TT Regular */
@font-face {
  font-family: "CiscoSansTT Regular";
  font-display: auto;
  src: url('/assets/fonts/Cisco_Sans/CiscoSansTTRegular.woff2') format('woff2'), url('/assets/fonts/Cisco_Sans/CiscoSansTTRegular.woff') format('woff')
}

/* Roboto Fonts */
@font-face {
  font-family: 'Roboto-Bold';
  font-display: auto;
  src: url('/assets/fonts/Roboto/Roboto-Bold.eot');
  src: url('/assets/fonts/Roboto/Roboto-Bold.eot') format('embedded-opentype'), url('/assets/fonts/Roboto/Roboto-Bold.ttf') format('truetype'), url('/assets/fonts/Roboto/Roboto-Bold.woff?llzbkh') format('woff'), url('/assets/fonts/Roboto/Roboto-Bold.woff2?llzbkh') format('woff2'), url('/assets/fonts/Roboto/Roboto-Bold.svg?llzbkh#icomoon') format('svg');
}

@font-face {
  font-family: 'Roboto-Light';
  font-display: auto;
  src: url('/assets/fonts/Roboto/Roboto-Light.eot');
  src: url('/assets/fonts/Roboto/Roboto-Light.eot') format('embedded-opentype'), url('/assets/fonts/Roboto/Roboto-Light.ttf') format('truetype'), url('/assets/fonts/Roboto/Roboto-Light.woff?llzbkh') format('woff'), url('/assets/fonts/Roboto/Roboto-Light.woff2?llzbkh') format('woff2'), url('/assets/fonts/Roboto/Roboto-Light.svg?llzbkh#icomoon') format('svg');
}

@font-face {
  font-family: 'Roboto-Medium';
  font-display: auto;
  src: url('/assets/fonts/Roboto/Roboto-Medium.eot');
  src: url('/assets/fonts/Roboto/Roboto-Medium.eot') format('embedded-opentype'), url('/assets/fonts/Roboto/Roboto-Medium.ttf') format('truetype'), url('/assets/fonts/Roboto/Roboto-Medium.woff?llzbkh') format('woff'), url('/assets/fonts/Roboto/Roboto-Medium.woff2?llzbkh') format('woff2'), url('/assets/fonts/Roboto/Roboto-Medium.svg?llzbkh#icomoon') format('svg');
}

@font-face {
  font-family: 'Roboto-Regular';
  font-display: auto;
  src: url('/assets/fonts/Roboto/Roboto-Regular.eot');
  src: url('/assets/fonts/Roboto/Roboto-Regular.eot') format('embedded-opentype'), url('/assets/fonts/Roboto/Roboto-Regular.ttf') format('truetype'), url('/assets/fonts/Roboto/Roboto-Regular.woff?llzbkh') format('woff'), url('/assets/fonts/Roboto/Roboto-Regular.woff2?llzbkh') format('woff2'), url('/assets/fonts/Roboto/Roboto-Regular.svg?llzbkh#icomoon') format('svg');
}


